<template>
  <v-list class="py-0">
    <template v-for="(group, gIndex) in properties">
      <v-subheader
        v-if="group.title"
        :key="gIndex"
        class="pr-0 theme-light body-1"
      >
        <span>{{ group.title }}</span>
      </v-subheader>
      <v-list-item
        v-for="(property, index) in group.items"
        :key="index.toString() + gIndex.toString()"
        class="px-0 dense list-item"
      >
        <v-layout
          v-if="property.type != 'array'"
          row
          class="ml-4"
        >
          <v-flex xs6>
            <span
              class="blue--text text--darken-4"
            >{{ property.title }}:</span>
          </v-flex>
          <v-flex
            xs6
          >
            <span v-if="property.type === 'digit'">{{
              property.value | separateDigits
            }}</span>
            <span v-else-if="property.type === 'date'">{{
              property.value | date
            }}</span>
            <span v-else-if="property.type === 'dateRange'">{{
              property.value | dateRange
            }}</span>
            <span v-else-if="property.type === 'string-list'">{{
              property.value | stringList(true)
            }}</span>
            <span v-else>{{ property.value }}</span>
          </v-flex>
        </v-layout>
        <v-layout
          v-else
          column
        >
          <v-list
            v-for="(items, index) in property.value"
            :key="index"
            :class="index > 0 ? 'pb-1 pt-0' : 'py-1'"
          >
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              class="px-0 dense list-item"
            >
              <v-layout row>
                <v-flex xs6>
                  <span
                    class="blue--text text--darken-4"
                  >{{ item.title }}:</span>
                </v-flex>
                <v-flex
                  xs6
                >
                  <span>{{ item.value }}</span>
                </v-flex>
              </v-layout>
            </v-list-item>
          </v-list>
        </v-layout>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      expandedGroups: [],
      showButton: true,
    };
  },
  computed: {
    properties() {
      return this.items;
    },
  },
};
</script>

<style scoped>
.list-item{
  height: 40px;
}
</style>
