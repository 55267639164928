import http from "./http.js";
import auth from "../auth.js";

async function getShippings(
    page = 1,
    pageSize = 9,
){
  const response = await http.get("isg-logistics/shippings", {
    params: {
      page,
      pageSize,
    },
  });
  return {
    totalItems: response.data.totalItems,
    items: response.data.result,
  };
}

async function getShipping(id) {
  const user = auth.getUser();
  const response = await http.get(`isg-logistics/shippings/${id}`, {
    params: {
      clientId: user.clientId,
    },
  });
  return response;
}

async function getGoodsDetail(id, clientId, shippingId) {
  const response = await http.get(`isg-logistics/goods-details/${id}`, {
    params: {
      clientId,
      shippingId,
    },
  });
  return response.data;
}

async function getMessages(id){
  const response = await http.get(`isg-logistics/shippings/${id}/messages`);
  return response.data;
}

async function markAsReadMessage(shippingId, messageId) {
  const response = await http.patch(`isg-logistics/shippings/${shippingId}/messages/${messageId}/mark-as-read`);
  return response;
}

export {
  getShippings,
  getShipping,
  getGoodsDetail,
  getMessages,
  markAsReadMessage,
};