<template>
  <v-layout>
    <app-loader
      title="Загрузка данных Поставки"
      :loading="!pageLoaded"
    />
    <v-layout
      v-show="pageLoaded"
      class="no-print"
      column
      align-center
    >
      <v-flex>
        <v-card
          :style="{ width: width + 'px',
                    'overflow-x': 'hidden', }"
          tile
          class="mt-0 mb-1 ml-0 pa-2 elevation-1"
        >
          <v-layout align-center>
            <v-tooltip bottom>
              <template v-slot:activator="{ on}">
                <v-btn
                  slot="activator"
                  small
                  icon
                  class="ma-0"
                  @click="$router.go(-1)"
                  v-on="on"
                >
                  <v-icon color="grey darken-1">
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Назад</span>
            </v-tooltip>
            <h2 class="px-2 title">
              {{ 'Поставка № ' + title }}
            </h2>
          </v-layout>
        </v-card>
      </v-flex>
      <v-card
        :style="{ height: blockHeight + 'px',
                  'overflow-y': 'auto',
                  width: width + 'px',
                  'overflow-x': 'hidden', }"
        tile
        class="mt-0 mb-1 ml-0 pa-2 elevation-1"
      >
        <app-info
          :items="infoItems"
        />
      </v-card>
    </v-layout>
  </v-layout>
</template>


<script>
import Info from "../../components/Info.vue";
import * as shippingsApi from "../../backend/shippingsApi.js";

export default {
  components: {
    appInfo: Info,
  },
  props: ["id"],
  data() {
    return {
      infoItems: [],
      loading: false,
      title: "",
      dataRestored: false,
      width: Number,
    };
  },
  computed: {
    blockHeight() {
      return window.innerHeight - 205;
    },
    pageLoaded() {
      return !!this.dataRestored;
    },
  },
  created() {
    this.getShipping();
  },
  methods: {
    async getShipping() {
      this.loading = true;
      const res = await shippingsApi.getShipping(this.id);
      this.title = res.data.number;
      this.getInfoItems(res.data);
      this.loading = false;
      this.dataRestored = true;
    },
    getInfoItems(data) {
      if (!data) {
        this.infoItems = [];
        return;
      }

      const widthInfo = {
        loadingAddress: data.loadingAddress?.title || "",
        comment: data.commentForShipping ? data.commentForShipping : "",
        unloadingAddress: data.unloadingAddress?.title || "",
        clientAlias: data.clientAlias ? data.clientAlias : "",
        manufacturer: data.manufacturer.length > 0 ? data.manufacturer.join("; ") : "",
        category: data.productCategory ? data.productCategory.join("; ") : "",
        timetable: data.loadingPlaceTimetable ? data.loadingPlaceTimetable : "",
      };
      this.getWidth(widthInfo);

      this.infoItems = [{
        title: "",
        items: [{
          title: "Дата создания",
          value: data.date ? data.date : "",
          type: "date",
        },
        {
          title: "Автор заявки",
          value: data.author ? data.author.title : "",
        },
        {
          title: "Клиент",
          value: data.client ? data.client.title : "",
        },
        {
          title: "Клиент по документам",
          value: data.clientAlias,
        },
        {
          title: "Менеджер",
          value: data.manager.title,
        },
        {
          title: "Внутренний номер заявки у клиента",
          value: data.internalRequestNumber,
        },
        ],
      },
      {
        title: "Первоначальное местонахождение груза",
        items: [{
          title: "Страна отправления",
          value: data.country.title,
        },
        {
          title: "Квадрат",
          value: data.loadingAddress ? data.loadingAddress.postcode : "",
        },
        {
          title: "Продавец",
          value: data.senderFactory ?
            data.senderFactory.title : data.senderWarehouse ?
              data.senderWarehouse.title : data.senderAddress ?
                data.senderAddress.title : "",
        },
        {
          title: "Производитель",
          type: "string-list",
          value: data.manufacturer.length > 0 ? data.manufacturer : "",
        },
        {
          title: "Адрес погрузки",
          value: data.loadingAddress.title,
        },
        {
          title: "Контактное лицо погрузки",
          value: data.loadingAddress.contactPerson ? data.loadingAddress.contactPerson.title : "",
        },
        {
          title: "Контактный номер телефона",
          value: data.loadingAddress.contactPerson ? data.loadingAddress.contactPerson.contactPhone : "",
        },
        {
          title: "Контактный email",
          value: data.loadingAddress.contactPerson ? data.loadingAddress.contactPerson.contactEmail : "",
        },
        {
          title: "Время работы места погрузки",
          value: data.loadingPlaceTimetable,
        },
        {
          title: "Дата закрытия фабрики",
          value: data.factoryClosingRequestDate,
          type: "date",
        },
        {
          title: "Комментарии к поставке",
          value: data.commentForShipping,
        },
        ],
      },
      {
        title: "Информация о товаре",
        event: "goods-details",
        items: [
          {
            title: "Категория товара",
            type: "string-list",
            value: data.productCategory,
          },
          {
            title: "Стоимость партии груза",
            value: data.invoiceSumGoodsDetails ? `${data.invoiceSumGoodsDetails} ${data.currency.title} (Описание товаров)` : `${data.invoiceSum} ${data.currency.title}`,
          },
          {
            title: "Валюта",
            value: data.currency.title,
          },
          {
            title: "Референс погрузки",
            value: data.loadingNumber,
          },
        ],
      },
      {
        title: "Адрес доставки товара",
        items: [{
          title: "Тип поставки",
          value: data.shippingType.title,
        },
        {
          title: "Страна-получатель по докам фабрики/отправителя",
          value: data.destinationCountry.title,
        },
        {
          title: "Страна",
          value: data.unloadingCountry.title,
        },
        {
          title: "Город",
          value: data.unloadingCity.title,
        },
        {
          title: "Адрес",
          value: data.unloadingAddress.title,
        },
        {
          title: "Контактное лицо выгрузки",
          value: data.contactsUnloading && data.contactsUnloading.length && data.contactsUnloading[0].contactPerson.title ?
            data.contactsUnloading[0].contactPerson.title : "",
        },
        {
          title: "Контактный номер телефона",
          value: data.contactsUnloading && data.contactsUnloading.length && data.contactsUnloading[0].contactPhone ?
            `+${data.contactsUnloading[0].contactPhone}` : "",
        },
        ],
      },
      {
        title: "Характеристики товара",
        event: "goods-summary",
        items: [{
          title: "Объем, м3",
          value: data.volume,
        },
        {
          title: "Вес брутто, кг",
          value: data.weight,
          type: "digit",
        },
        ],
      },
      {
        title: "Доп. инфо в Заявке",
        lastGroup: true,
        items: [{
          title: "Нужна ли EX?",
          value: data.needEx.title,
        },
        {
          title: "Негабарит",
          value: data.startOversized ? "Да" : "Нет",
        },
        {
          title: "Груз оплачен",
          value: data.startIsPayed ? "Да" : "Нет",
        },
        {
          title: "Готовность Заявка",
          value: data.readyDate,
          type: "date",
        },
        {
          title: "Доставка Заявка",
          value: { from: data.wantedDateFrom, to: data.wantedDateTo },
          type: "dateRange",
        },
        ],
      },
      ];
    },
    getWidth(info) {
      const loadingAddressWidth = this.findWidth(info.loadingAddress);
      const commentWidth = this.findWidth(info.comment);
      const unloadingAddressWidth = this.findWidth(info.unloadingAddress);
      const clientAliasWidth = this.findWidth(info.clientAlias);
      const manufacturerWidth = this.findWidth(info.manufacturer);
      const categoryWidth = this.findWidth(info.category);
      const timetableWidth = this.findWidth(info.timetable);

      const auto = [loadingAddressWidth, commentWidth, unloadingAddressWidth, clientAliasWidth, manufacturerWidth, categoryWidth, timetableWidth].includes("auto");
      if (auto) {
        this.width = window.innerWidth;
      } else {
        this.width = Math.max.apply(null, [loadingAddressWidth, commentWidth, unloadingAddressWidth, clientAliasWidth, manufacturerWidth, categoryWidth, timetableWidth]);
      }
    },
    findWidth(item) {
      if (item.length > 75) {
        return "auto";
      } else if (item.length > 75) {
        return 1500;
      } else if (item.length > 65) {
        return 1400;
      } else if (item.length > 55) {
        return 1300;
      } else if (item.length > 45) {
        return 1000;
      } else if (item.length > 35) {
        return 900;
      } else {
        return 800;
      }
    },
  },
};
</script>
